import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { FeedbackProvider } from '../providers/feedback.provider'
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AngularFirestore } from '@angular/fire/firestore';
import { BreakpointObserver } from '@angular/cdk/layout';

interface User {
  name: string,
  image: string
}

@Component({
  selector: 'profile-modal',
  templateUrl: './profile.modal.html',
  styleUrls: ['./profile.modal.scss']})
export class ProfileModal {

  uid: string;

  displayname: string;
  image: string;
  user: User = {
    name: "",
    image: ""
  };

  recipes: any[];

  constructor(navParams: NavParams,
    private afs: AngularFirestore,
    private modalController: ModalController,
    public afAnalytics: AngularFireAnalytics,
    public afAuth: AngularFireAuth,
    public breakpointObserver: BreakpointObserver,
    public feedback: FeedbackProvider) {
      afAnalytics.logEvent('view_user_profile');
      if(navParams.data.uid) {
        this.uid = navParams.data.uid;
        this.afs.collection('users').doc(this.uid).valueChanges().subscribe( (user: User) => {
          this.user = user;
        });

        this.afs.collection<any>('recipes', ref => ref.where('author', '==', this.uid).where('private', '==', false)
          .orderBy('name', 'asc') ).valueChanges().subscribe( recipes => {
          this.recipes = recipes;
        });

      } else {
        this.dismiss();
      }
  }
  
  segmentChanged(event) {
    //this.signin = event.detail.value == 'signin';
    //this.signup = event.detail.value == 'signup';
  }

  dismiss() {
    this.modalController.dismiss();
  }

}