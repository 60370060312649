import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { FeedbackProvider } from '../providers/feedback.provider'
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'login-modal',
  templateUrl: './login.modal.html',
  styleUrls: ['./login.modal.scss']})
export class LoginModal {

  signin: boolean = true;
  signup: boolean = false;

  email: string;
  password: string;

  displayname: string;

  constructor(private modalController: ModalController,
    public afAnalytics: AngularFireAnalytics,
    public afAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    public feedback: FeedbackProvider) {
      afAnalytics.logEvent('open_profile');
      afAuth.auth.onAuthStateChanged(user => {
        if(user != null) {
          if(user.emailVerified) {
            this.displayname = user.displayName;
          } else {
            afAnalytics.logEvent('email_not_verified');
            feedback.presentToast("Vous allez recevoir un e-mail pour valider la création de votre compte")
            user.sendEmailVerification();
            afAuth.auth.signOut();
          }
        } 
      });
  }
  
  selectProfilePicture(event) {

    this.afAnalytics.logEvent('upload_profile_picture');

    this.feedback.presentLoader("Téléchargement de l'image en cours");

    const file = event.target.files.item(0)
 
    if (file.type.match('image.*')) {
      const filePath = `users/${this.afAuth.auth.currentUser.uid}/` + `${new Date().getTime()}-${file.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);

      task.then(snapshot => {
        fileRef.getDownloadURL().subscribe( value => {
          this.afAuth.auth.currentUser.updateProfile({
            photoURL: value
          }).then( data => {
            this.feedback.presentToast('Image téléchargée avec succès!');
          }).catch(error => {
            this.feedback.presentToast(error.message);
          });          
        });
      }).catch( error => {
        this.feedback.presentToast("Erreur lors du téléchargement de l'image...");
      }).finally(() => {
        this.feedback.dismissLoader();
      });
      
    } else {
      this.feedback.presentToast("Mauvais format.");
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }

  login() {
    this.afAnalytics.logEvent('click_login');
    this.afAuth.auth.signInWithEmailAndPassword(this.email, this.password).then( data => {
      this.feedback.presentToast(`Vous êtes connecté en tant que ${data.user.displayName ? data.user.displayName : data.user.email}`);
      this.displayname = data.user.displayName;
    }).catch(error => {
      this.feedback.presentToast(error.message);
    });
  }

  createAccount() {
    this.afAnalytics.logEvent('click_signup');
    this.afAuth.auth.createUserWithEmailAndPassword(this.email, this.password).then( data => {
      this.signin = true;
      this.signup = false;
    }).catch(error => {
      this.feedback.presentToast(error.message);
    });
  }

  resetPassword() {
    this.feedback.promptEmail('Mot de passe oublié ?', 'Veuillez saisir votre adresse email', (email) => {
      this.afAnalytics.logEvent('reset_password');
      try {
        this.afAuth.auth.sendPasswordResetEmail(email.value).then( data => {
          this.feedback.presentToast(`Un email a été envoyé à ${email.value}`);
        });
      } catch(e) {
        console.log('failed to send', e);
        this.feedback.presentToast(e);
      }
    });
  }

  logout() {
    this.afAnalytics.logEvent('click_logout');
    this.afAuth.auth.signOut();
  }

  updateDisplayName() {
    this.afAuth.auth.currentUser.updateProfile({
      displayName: this.displayname
    }).then( data => {
      this.feedback.presentToast('Mise à jour effectuée avec succès !');
    }).catch(error => {
      this.feedback.presentToast(error.message);
    });
  }

  segmentChanged(event) {
    this.signin = event.detail.value == 'signin';
    this.signup = event.detail.value == 'signup';
  }

}