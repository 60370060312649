import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { mergeMapTo } from 'rxjs/operators';
import { FeedbackProvider } from './providers/feedback.provider';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  
  private userDocument: AngularFirestoreDocument<any>;

  constructor(
    private platform: Platform,
    private afs: AngularFirestore,
    public afAnalytics: AngularFireAnalytics,
    private afAuth: AngularFireAuth,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private afMessaging: AngularFireMessaging,
    private feedback: FeedbackProvider
  ) {
    this.initializeApp();
    
    this.afAuth.auth.onAuthStateChanged( state => {
      if(this.afAuth.auth.currentUser) {
        this.afAnalytics.setUserId(this.afAuth.auth.currentUser.uid);
        this.userDocument = this.afs.doc<any>('users/' + this.afAuth.auth.currentUser.uid);
        this.userDocument.set({
          name: this.afAuth.auth.currentUser.displayName,
          image: this.afAuth.auth.currentUser.photoURL
        }, { merge: true} );
      } else {
        this.afAnalytics.setUserId(undefined);
      }

      this.afMessaging.requestPermission
        .pipe(mergeMapTo(this.afMessaging.tokenChanges))
        .subscribe(
          (token) => {
            console.log('Permission granted! Save to the server!', token);
            this.userDocument.set({'token': token, 'uid': this.afAuth.auth.currentUser.uid}, { merge: true} );
          },
          (error) => { console.error(error); },  
        );
    
      this.afMessaging.messages
        .subscribe((message: { notification: { title: string, body: string, click_action: string}}) => { 
          feedback.presentToastWithButton(message.notification.body, 'Ouvrir', 'open', () => {
            window.open(message.notification.click_action, '_blank');
          });
        });
    });

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
