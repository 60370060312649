// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAI9Zh6IQwVqpyz3wBsDMrSRW7-lEAeN94",
    authDomain: "get-cookin-dev-12117.firebaseapp.com",
    databaseURL: "https://get-cookin-dev-12117.firebaseio.com",
    projectId: "get-cookin-dev-12117",
    storageBucket: "get-cookin-dev-12117.appspot.com",
    messagingSenderId: "803523452703",
    appId: "1:803523452703:web:d4d206d8ef57dd938a1a2a",
    measurementId: "G-Q56KCJGHZ1"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
