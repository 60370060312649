import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'create', loadChildren: './create/create.module#CreatePageModule' },
  { path: 'recipes/edit/:slug', loadChildren: './create/create.module#CreatePageModule' },
  { path: 'recipes/:slug', loadChildren: './recipe-detail-page/recipe-detail-page.module#RecipeDetailPagePageModule' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
