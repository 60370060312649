import { Injectable } from '@angular/core';
import { ToastController, LoadingController, AlertController } from '@ionic/angular';

@Injectable()
export class FeedbackProvider {

  alert: any;

  constructor(private toastController: ToastController,
    private loadingController: LoadingController,
    private alertController: AlertController) {}

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  async presentToastWithRetry(message: string, retryCallback) {
    const toast = await this.toastController.create({
      message: message,
      duration: 4000,
      buttons: [
        {
          side: 'end',
          icon: 'refresh',
          text: 'réessayer',
          handler: retryCallback
        }
      ]
    });
    toast.present();
  }

  async presentToastWithButton(message: string, buttonText: string, icon: string, clickCallback) {
    const toast = await this.toastController.create({
      message: message,
      duration: 4000,
      buttons: [
        {
          side: 'end',
          icon: icon,
          text: buttonText,
          handler: clickCallback
        }
      ]
    });
    toast.present();
  }

  async presentConfirmationPopup(message: string, yesCallback, noCallback = () => { this.alert.dismiss(); }) {
    this.alert = await this.alertController.create({
      message: message,
      buttons: [
        {
          text: 'Non',
          role: 'no',
          cssClass: 'secondary',
          handler: noCallback
        }, {
          text: 'Oui',
          handler: yesCallback
        }
      ]
    });

    await this.alert.present();
  }

  async promptURL(title: string, message: string, callback) {
    const alert = await this.alertController.create({
      header: title,
      inputs: [
        {
          name: 'value',
          type: 'url',
          placeholder: message
        }
      ],
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Ok',
          handler: (data) => {
            callback(data);
          }
        }
      ]
    });

    await alert.present();
  }

  async promptText(title: string, message: string, callback) {
    const alert = await this.alertController.create({
      header: title,
      inputs: [
        {
          name: 'value',
          type: 'text',
          placeholder: message
        }
      ],
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Ok',
          handler: (data) => {
            callback(data);
          }
        }
      ]
    });

    await alert.present();
  }

  async promptEmail(title: string, message: string, callback) {
    const alert = await this.alertController.create({
      header: title,
      inputs: [
        {
          name: 'value',
          type: 'email',
          placeholder: message
        }
      ],
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Ok',
          handler: (data) => {
            callback(data);
          }
        }
      ]
    });

    await alert.present();
  }

  async presentLoader(message: string) {
    const loader = await this.loadingController.create({
      message: message
    });
    await loader.present();
  }

  async dismissLoader() {
    await this.loadingController.dismiss();
  }
}